.answerItemComponent {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px 10px 0;
    background: #f2f2f3;
    padding: 3px 5px;
    .questionIndex {
        display: inline-block;
        min-width: 2.4em;
        font-size: 12px;
        font-weight: 500;
        color: rgba(50,50,50,1);
    }
    .userAnswer {
        display: inline-block;
        width: 40px;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        background: #FA4F48;
        font-size: 16px;
        color: #fff;
    }
    .correct {
        background: #44D7B6;
    }
    .smallFont {
        font-size: 10px;
    }
}