.inputWrapper {
  display: flex;
  align-items: center;
}
.flexg {
  flex-grow: 1;
  :global {
    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}
.btn {
  display: inline-block;
  height: 32px;
  padding: 0 11px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}