.editCommentContainer, .comment-container {
    padding: 20px 3px;
    padding-top: 5px;
}
.editCommentContainer {
    background: #f0f0f0;
    padding-top: 15px;
}
.no-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.flexgrow {
    flex-grow: 1;
}
.marginBottomT {
    margin-bottom: 10px;
}
.feedback-container {
    background: #f2f2f2;
    padding: 10px 5px;
    position: relative;
}
.color333 {
    color: #333;
}
.rightBottomButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #108ee9;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
}
.weight600 {
    font-weight: 600;
}
.font13 {
    font-size: 13px;
}
.font15 {
    font-size: 15px;
}
.cancelButton {
    background: #ddd !important;
    color: #fff !important;
    margin-right: 9px;
}
.feedbackImg-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
}
.img-item {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 320px;
    height: 240px;
}
.progressContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50000;
}
.divider {
    margin-top: 10px;
    margin-bottom: 15px;
    width: 100%;
    height: 1px;
    background: #fff;
}
.ant-progress-text {
    font-size: 28px;
    color: orange !important;
}
.colorRed {
    color: red;
}
.mr9 {
    margin-right: 9px;
}