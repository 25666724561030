.candidateListPage {
    .filter-container {
        background-color: #fff;
        padding: 0 20px;
        color: #565964;
    }
    .height60 {
        height: 60px;
    }
    .height50 {
        height: 50px;
    }
    .flexG {
        flex-grow: 1;
    }
    .flexrc {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .marginRight25 {
        margin-right: 25px;
    }
    .borderBottom {
        border-bottom: 1px solid #f0f0f0;
    }
    .fz20 {
        font-size: 20px;
        font-weight: 450;
    }
    .fz16 {
        font-size: 16px;
        font-weight: 400;
    }
    .mr40 {
        margin-right: 40px;
    }
    .correct {
        display: inline-block;
        width: 88px;
        height: 30px;
        font-size: 14px;
        font-weight: 400px;
        border-radius:4px;
        text-align: center;
        line-height: 30px;
      }
      .done {
        cursor: pointer;
        background:rgba(96,174,239,1);
        color: rgba(255,255,255,1);
      }
}