.systemM {
    display: flex;
    .tree {
        display: inline-block;
        vertical-align: top;
        border: 1px solid #d4d4d4;
        width: 350px;
        margin-left: 67px;
        border-radius: 5px;
    }
    .checkbox {
        width: 90px;
        .ant-checkbox-inner {
            display: inline-block;
        }
    }
}