.interviewListContainer {
  .inlineDiv {
    display: inline-block;
    margin-right: 20px;
  }
  .selectW {
    width: 150px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .rightBtn {
    position: absolute;
    right: 0;
  }
}

.margin5 {
  margin: 5px;
}

.roundCorner {
  border-radius: 5px;
}

.bold-label {
  font-size: 14px;
  font-weight: 600;
}

.white-panel {
  background-color: white;
  padding: 10px;
  flex-wrap: wrap;

  button {
    margin: 5px 10px 0 0 ;
  }
}

.position-panel {
  & > div{
    display: flex;
  }

  span {
    margin-right: 20px;
  }

  button span{
    margin-right: 0
  }

  div {
    margin-bottom: 5px;
  }
}

.position-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.position-label {
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
  flex-shrink: 0;
}

.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
