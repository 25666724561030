.userConfigPage {
  .flex {
    display: flex;
  }
  .margin5 {
    margin: 5px;
  }
  .mr15 {
    margin-right: 15px;
  }
  .w500 {
    width: 500px;
    margin-right: 20px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
}