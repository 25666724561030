.interviewClassPageWrapper {
  .inblock {
    display: inline-block;
  }
  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    label {
      width: 5em;
    }
  }
  .filterRow {
    margin-bottom: 10px;
  }
  .flexRow {
    .filterItem+.filterItem {
      margin-left: 40px;
    }
  }
  .needMb10 {
    .filterItem {
      margin-bottom: 10px;
    }
  }
  .w20em {
    width: 30em;
  }
  .mr40 {
    margin-right: 40px;
  }
  .mr20 {
    margin-right: 20px;
  }
}