.interviewPage {
    .ant-radio-group {
        padding: 10px 0;
        float: right;
    }
    .flexrc {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .childDiv {
        width: 100%;
        flex-wrap: wrap;
        padding: 14px 0 6px 12px;
        font-size: 14px;
        font-weight: 400;
        background-color: #fff;
    }
    .borderBottom {
        border-bottom: 1px solid #f0f0f0;
    }
    .mb5 {
        margin-bottom: 5px;
    }
    .marginRight25 {
        margin-right: 25px;
    }
    .mr {
        margin-right: 40px;
    }
    .exerciseTitle {
        font-size: 12px;
        font-weight: 600;
        color: #262626;
        margin-bottom: 3px;
    }
    .testModule {
        padding: 15px;
    }
    .questionTest {
        color: #262626;
        font-weight: 450;
        font-size: 14px;
    }
    .noMore3Line {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .flexg {
		flex-grow: 0.98;
    }
    .mb30 {
        margin-top: 10px;
    }
}
.studentRemark {
    min-height: 80px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #eee;
    background: #f9f9f9;
}
.row23029032h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selcectioncsod {
    width: 400px;
  }
}
.sdsjdshdj2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sdhjs {
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}