.flexrc {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .inputRow {
    margin-bottom: 10px;
  }
  .i300 {
    width: 300px !important;
  }
  .mr15 {
    margin-right: 15px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .flexg {
    flex-grow: 1;
  }
  .mt20 {
    margin-top: 20px;
  }