.classExercisePage {
	.back {
		display: none;
		z-index: 10;
		user-select: none;
		position: absolute;
		cursor: pointer;
		width: 40px;
		height: 40px;
		right: 100px;
		bottom: 100px;
		font-size: 12px;
		color: #fff;
		line-height: 14px;
		padding: 8px;
		background: rgba(0,0,0,0.55);
		border-radius: 50%;
	}
	.back:hover {
		background: rgba(0,0,0,0.85);
	}
	.ueSpan,
	.clickSpan {
		color: #1890ff;
		cursor: pointer;
		font-size: 14px;
	}
	.topTitle {
		position: sticky;
		top: 0;
		padding: 15px;
		z-index: 100;
		box-shadow: 1px 1px 6px #999;
	}
	.btnLeft {
		margin-right: 20px;
	}
	.opacityInput {
		display: inline-block;
		width: 64px;
		height: 22px;
		opacity: 0;
	}
	.pdfButton {
		width: 64px;
		height: 22px;
		margin-top: 5px;
		position: relative;
		.btnName {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.mr5 {
		margin-right: 5px;
	}
}
