.ant-breadcrumb-link {
  font-size: 18px;
}
.ant-breadcrumb-separator {
  font-size: 18px;
}
.backList {
  cursor: pointer;
}
.single-container {
  background-color: #fff;
}
.flexrc {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.childDiv {
  width: 100%;
  flex-wrap: wrap;
  padding: 14px 0 6px 12px;
  font-size: 14px;
  font-weight: 400;
}
.mb5 {
  margin-bottom: 5px;
}
.marginRight25 {
  margin-right: 25px;
}
.borderBottom {
  border-bottom: 1px solid #f0f0f0;
}
.flexG {
  flex-grow: 1;
}
.nextAndPrevBtn {
  position: sticky;
  bottom: 0;
  height: 50px;
  padding: 0 20px;
  background: #f2f2f2;
  transition: all ease 2s;
}
.opacity1 {
  opacity: 1;
}
.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.height100 {
  height: 100%;
}
.userName {
  padding: 2px 10px;
  color: #108ee9;
  border: 1px solid #108ee9;
}
.flexg {
  flex-grow: 1;
}
.mr30 {
  margin-right: 30px;
}