.bannerRelease {
  .mb30 {
    margin-bottom: 30px;
  }
  .labelWidth {
    display: inline-block;
    width: 6em;
  }
  .mr10 {
    margin-right: 10px;
  }
  .bannerImg {
    display: block;
    max-width: 375px;
    height: auto;
    margin: 10px 76px;
  }
  .w360 {
    width: 360px;
  }
  .w500 {
    width: 500px !important;
  }
}
