.exerciseRankStudent {
    #lastDate {
        color: red;
    }
    .active {
        animation:run 2s;
    }
    @keyframes run {
        from {
            color: orange;
            background: #eee;
        }
        to {
            color: red;
            background: #fff;
        }
    }
    .ml10 {
        margin-left: 10px;
    }
    .examineAnswerLists {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        background: #fff;
        border: 1px solid #f0f0f0;
        padding: 15px 10px;
    }
    .title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .changeBtn {
        display: flex;
        align-items: center;
        position: sticky;
        z-index: 2;
        background: #e6f3f7;
        padding: 10px 0;
        top: 0;
        width: 100%;
    }
    .name {
        color: blue;
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
    }
}
