.exercise-container {
    padding: 20px;
    .metaData_container {
        padding: 15px 6px;
        margin-bottom: 35px;
        border: 1px solid #ccc;
        border-radius: 10px;
        .flexr {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .header {
            padding-bottom: 15px;
            border-bottom: 1px solid #f0f0f0;
        }
        .footer {
            padding-top: 15px;
            border-top: 1px solid #f0f0f0;
        }
        .spanRight {
            margin-right: 10px;
            font-weight: bold;
            font-size: 12px;
        }
        .tinct {
            color: red;
        }
        .nono {
            padding: 20px 15px;
            font-size: 16px;
            font-weight: bold;
        }
        .notDone {
            color: red;
        }
    }
    .outsideShenlun {
        border: 1px solid red;
    }
    .singleModule {
        color: #108ee9;
        font-size: 16px;
        font-weight: 500;
        margin: 10px 0;
    }
    .mr30 {
        margin-right: 30px;
    }
}
.emptyWorkContainer0348 {
    height: 200px;
    line-height: 200px;
    font-size: 28px;
    color: #555;
    text-align: center;
}
.positioning {
    position: fixed;
    right: 10px;;
    background: #f2f2f2;
    top: 60px;
    z-index: 100;
}