.CoursenumCalendarComponent {
  .inputRow + .inputRow {
    margin-top: 20px;
  }
  .flexrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .flexcolumn {
    display: flex;
    flex-direction: column;
  }
  .flexrow label {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 12em;
  }
  .input400 {
    width: 100% !important;
  }
  .select320 {
    width: 100% !important;
  }
  .calendarWrapper {
    width: 600px;
    height: 440px;
    border: 1px solid #f0f0f0;
  }
  .dateClass {
    width: 64px;
    height: 48px;
    color: #323232;
    background:rgba(238,238,238,1);
    box-shadow:0px 0px 1px rgba(226,226,226,1);
    border-radius:4px;
    margin-right: 12px;
    margin-bottom: 12px;
    padding: 6px;
  }
  .activeDate {
    background: #7BC0F2;
  }
  .rc-calendar-body {
    height: 440px;
  }
  .crow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .crow1 {
    line-height: 1.3em;
    margin-bottom: 3px;
  }
  .span32 {
    color: #323232;
    font-size: 16px;
    font-weight: 600;
  }
  .flexg {
    flex-grow: 1;
  }
  .plusIcon {
    width: 12px;
    height: 12px;
  }
  .inc {
    width:56px !important;
    height:20px !important;
    background:rgba(255,255,255,1);
    border-radius:4px;
  }
  .calendarWrapper .ant-input-number-input {
    height: 16px !important;
  }
  .calendarIcon {
    width: 20px;
    height: 20px;
    margin-left: 9px;
  }
  .ml15 {
    margin-left: 15px;
  }
  .mlr8 {
    margin-left: 8px;
    margin-right: 8px;
  }
}