.dataOrderContainer {
    .orderContainer {
        color: #333333;
        font-size: 18px;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
    }
    .conditionContainer {
        margin: 20px 20px 0px 10px;
        align-items: center;
        label {
            min-width: 4em;
            margin-right: 5px;
        }
    }
    .w260 {
        width: 180px;
    }
    .mr30 {
        margin-right: 30px;
    }
}