.courseInfo {
    .cover {
        width: 100%;
        max-width: 20em;
        height: 10em;
        position: relative;
        overflow: hidden;
        border: 1px dashed rgba(0, 0, 0, 0.2);
    }
    .checkOpt {
        display: flex;
        flex-direction: column;
    }
    .inputRow + .inputRow {
        margin-top: 20px;
    }
    .flexrow {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .flexcolumn {
        display: flex;
        flex-direction: column;

    }
    .flexrow label {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 12em;
    }
    .input400 {
        width: 100% !important;
    }
    .select320 {
        width: 100% !important;
    }
}