.canvasComponent-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
}
.canvasMask {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    z-index: 999;
}
.canvasComponentWrapper{
    position: absolute;
    left: 50%;
    top: 20px;
    max-height: cacl(100vh - 40);
    bottom: 20px;
    transform: translateX(-50%);
    z-index: 10000;
    background: #fff;
    overflow-y: scroll;
    max-width: 90%;
    // max-height: 90%;
}
.closeImg {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    z-index: 10001;
    cursor: pointer;
}
.loading-container {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20000;
}
.canvas {
    border: 2px solid #f0f0f0;
}
.control-ops {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0 3px;
}
.mr9 {
    margin-right: 9px;
}
