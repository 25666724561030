.msg {
    color: red;
}
.hasPay {
    color: #10AA80;
}
.noPay {
    color: #FF1D1D;
}
.rowFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.columnFlex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}
.centerFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.content {
    width: 100%;
}
.content1 {
    font-size: 18px;
    color: #333333;
    line-height: 16px;
    margin: 0;
    padding: 0;
    margin-bottom: 31px;
}
.order-content2 {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    background-color: #E6F3F8;;
    padding-left: 40px;
    position: relative;
}
.order-content2>h4 {
    height: 50px;
    width: 50px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    color: #00B3EE;
    cursor: pointer;
}
.order-content2 > .active {
    background: #57A4FF;
    color: #fff;
}
.content3>div {
    text-align: center;
    width: 33.333%;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}
.content4c {
    height: 166px;
    background: #F1F2F6;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    margin-bottom: 30px;
}
.left-position {
    padding-left: 40px;
}
.content4c-header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: #333333;
    background-color: #F1F2F6;
}
.content4c-header>div:nth-of-type(1) {
    width: 55%;
}
.content4c-header>div:nth-of-type(2) {
    width: 15%;
    text-align: center;
}
.content4c-header>div:nth-of-type(3) {
    width: 30%;
    text-align: right;
    padding-right: 15px;
}
.content4c-content {
    background-color: #fff;
}
.content4c-content>div:nth-of-type(1) {
    width: 33%;
    border-right: 1px solid #E6E6E6;
}
.content4c-content>div:nth-of-type(2) {
    margin-left: 20px;
    width: 25%;
    border-right: 1px solid #e6e6e6;
}
.content4c-content>div:nth-of-type(3) {
    margin-left: 50px;
    border-right: 1px solid #e6e6e6;
}
.content4c-content>div:nth-of-type(4) {
    text-align: right;
    padding-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}
.content4c-c {
    width: 25%;
    height: 116px;
}
.content4c-c4 {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.content4c-c4>div {
    box-sizing: border-box;
    height: 96px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}
.content4c-c1 {
    font-size: 16px;
}
.content4c-c1>div {
    margin-bottom: 12px;
}
.content4c-c1>div:last-child {
    margin-bottom: 0;
}
.content4c-c1>div:nth-child(2)>span:nth-child(1) {
    margin-right: 20px;
}
/* 赠品样式 */
.content4c-c1>div:last-child>span:first-child {
    background: #FF7D00;
    border-radius: 2px;
    font-size: 11px;
    padding: 2px 3px;
    color: #FFFFFF;
    margin-right: 10px;
}
.content4c-c1>div:last-child>span:last-child {
    font-size: 12px;
}
.content4c-c1>div:nth-of-type(1) {
    font-weight: bold;
}
.content4c-c1>div:nth-of-type(1)>span {
    margin-left: 61px;
    color: #ff7d00;
}
.content4c-c2>div {
    font-size: 16px;
}
.content4c-c2>div:nth-child(1) {
    margin-bottom: 9px;
}
.content4c-c3>div:first-child {
    font-size: 16px;
}
.content4c-c3>div:nth-of-type(2) {
    font-size: 16px;
}
.editSpan {
    font-size: 16px;
    color: #1B9AD6;
    line-height: 16px;
    cursor: pointer;
}
.content4c-c3>div:nth-child(1) {
    margin-bottom: 9px;
}
.content4c-c3>div:nth-child(2) {
    margin-bottom: 9px;
}
.gk-modal-body>div {
    font-size: 16px;
    color: #333333;
}
.gk-modal-body>div:nth-child(1),.gk-modal-body>div:nth-child(5) {
    font-size: 14px;
    color: #666666;
    line-height: 14px;
}
.gk-modal-body>div:nth-child(1) {
    margin-bottom: 17px;
}
.gk-modal-body>div:nth-child(5) {
    margin-top: 32px;
    margin-bottom: 32px;
}
.gk-modal-body>div:nth-child(6) {
    margin-bottom: 10px;
}
.gk-modal-body input {
    margin-left: 10px;
}
.btn {
    background-color: #1B9AD6;
    border-radius: 4px;
    color: #fff;
}
.btn:hover {
    color: #fff;
}
.bigSpan {
    font-size: 16px;
}
.buyStatus {
    font-size: 14px !important;
    font-weight: bold;
}
.rightH4 {
    width: 150px !important;
    position: absolute !important;
    right: 30px;
}
.filterModal > div {
    position: relative;
    margin-bottom: 15px;
}
.filterModal > div > input {
    position: absolute;
    min-width: 175px;
    left: 90px;
}
.order-content2 > .marginRight {
    margin-right: 10px;
}


/** new version code begins */
.orderContainer {
    .info {
        font-size: 14px;
        font-weight: bold;
      }
    .topHeaderWrapper {
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: #333;
        margin-bottom: 32px;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .conditionContainer {
        label {
            min-width: 4em;
        }
    }
    .tableHeader {
        background: #E6F3F8;
        width: 100%;
        height: 44px;
        justify-content: space-around;
        div {
            font-size: 16px;
            font-weight: 500;
            color: rgba(51,51,51,1);
        }
    }
    .w260 {
        width: 180px;
    }
    .mt20 {
        margin-top: 20px;
    }
}