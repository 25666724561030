.courseClassInfo {
  .absolutePost {
    position: fixed;
    top: 70px;
    right: 30px;
    width: 240px;
    height: 106px;
  }
  .inputRow {
    width: 70%;
  }
  .w80 {
    width: 80% !important;
  }
  .label6 {
    width: 5em;
  }
  .mr6 {
    margin-right: 6px;
  }
  .blockInline {
    display: block;
  }
  .w100 {
    width: 100% !important;
  }
  .mr10f {
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
  }
}