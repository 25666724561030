.x-navbar {
  .navbar-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: #000;
    font-size: 12px;
    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      border-radius: 50%;
      cursor: pointer;
    }
    span {
      display: inline-block;
      margin-right: 10px;
      color: black;
      cursor: pointer;
    }
    .info {
      color: crimson;
      font-weight: bold;
      border: 0;
      padding: 0;
      margin-right: 0;
    }    
  }
  h5, h6 {
    margin-top: 10px;
  }
}
