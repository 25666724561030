.batch-container {
    .active {
        color: #108ee9;
        cursor: pointer;
    }
    .w260 {
        width: 260px;
    }
    .mr10 {
        margin-right: 10px;
    }
    .ml50 {
        margin-left: 50px;
    }
    .ml30 {
        margin-left: 30px;
    }
    .mt10 {
        margin-top: 10px;
    }
    .mb30 {
        margin-bottom: 30px;
    }
    .mt30 {
        margin-top: 30px;
    }
    .w130 {
        width: 130px;
    }
    .mr30 {
        margin-right: 30px;
    }
    .position {
		position: fixed;
		top: 70px;
        right: 30px;
        z-index: 10;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
    }
    .mt5 {
        margin-top: 5px;
    }
    .classStyle {
        margin-left: 40px;
        color: red;
        font-size: 12px;
    }
    .mt15 {
        margin-top: 15px;
    }
}