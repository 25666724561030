.interviewClassPage {
	.num {
		width: 100px;
	}
	.line {
		border-top: 1px solid #ddd;
	}
	.line :first {
		border: none;
	}
    .mb10 {
		margin-bottom: 10px;
    }
    .flexRow {
		display: flex;
		flex-direction: row;
		align-items: center;
    }
    .flexg {
		flex-grow: 1;
    }
    .ml45 {
		margin-left: 25px;
	}
	.position {
		position: fixed;
		top: 70px;
		right: 20px;
		z-index: 10;
	}
    .clickSpan {
		color: #108ee9;
		cursor: pointer;
		font-size: 12px;
    }
    .input600 {
		width: 600px;
		margin-right: 15px;
    }
    .mtb20 {
		margin-top: 20px;
		margin-bottom: 20px;
    }
    .studentDetail {
		display: inline-block;
		width: 120px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		color: #ffffff;
		background:rgba(96,174,239,1);
		border-radius:4px;
		cursor: pointer;
	}
	.tHeader {
		width: 98%;
		height: 44px;
		background: #d8d8d8;
		font-size: 16px;
		line-height: 44px;
		display: flex;
		flex-direction: row;
	}
	.notData {
		width: 98%;
		font-size: 18px;
		height: 44px;
		line-height: 44px;
		text-align: center;
		background: #fff;
	}
	.title {
		margin-left: 10px;
		font-size: 16px;
		color: #333333;
		font-weight: 450;
	}
	.w10 {
		width: 10%;
	}
	.w8 {
		width: 8%;
	}
	.w15 {
		width: 17%;
	}
	.w65 {
		width: 65%;
	}
	.w70 {
		width: 70%;
	}
	.mt20 {
		margin-top: 20px;
	}
}