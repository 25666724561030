.classDetail-container {
    font-size: 15px;
    font-weight: 500;
    .loadingDiv {
        margin-top: 100px;
        text-align: center;
    }
    .flexrc {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .mr10 {
        margin-right: 10px;
    }
    .filter-container {
        background-color: #fff;
        padding: 0 20px;
        .filter-label {
            margin-right: 9px;
            font-size: 14px;
            color: #666;
        }
    }
    .height60 {
        height: 60px;
    }
    .flexG {
        flex-grow: 1;
    }
    .classNameSelect {
        width: 20em;
    }
    .courseNameSelect {
        width: 22em;
    }
    .dayNumberSelect {
        width: 6em;
    }
    .borderBottom {
        border-bottom: 1px solid #f0f0f0;
    }
    .childDiv {
        width: 100%;
        flex-wrap: wrap;
        padding: 14px 0 6px 0;
    }
    .footer-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .marginRight {
        margin-right: 20px;
    }
    .marginRight15 {
        margin-right: 15px;
    }
    .ellipsis {
      // width: 25%;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .mb5 {
        margin-bottom: 5px;
    }
}
.mr {
    margin: 0 35px 20px 0;
}
