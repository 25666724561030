.commonSenseInfo {
  .mb30 {
    margin-bottom: 30px;
  }
  .flexrc {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .inputRow {
    margin-bottom: 20px;
  }
  .inputRow label {
    width: 6em;
  }
  .i350 {
    width: 350px !important;
  }
}