.roleWrap{
    .role {
        display: flex;
    }
    .topBtn {
        display: flex;
        justify-content: space-between;
    }
    .listRole {
        position: fixed;
        height: 80%;
        overflow: auto;
        width: 16%;
        box-shadow: 0px 7px 7px -7px #5E5E5E;
    }
    .listRole::-webkit-scrollbar {
        display:none;
    }
    .tableList {
        flex: 1;
        margin-left: 20%;
    }
    .ant-table-wrapper {
        margin-top: 20px;
    }
    .ant-tree {
        background: #F0F2F5;
    }
}