.add-modify {
    .flexRow {
        display: flex;
        flex-direction: row;
    }
    .pageTitle {
        font-size: 20px;
        font-weight: 450;
    }
    .mt5 {
        margin-top: 5px;
    }
    .mr10 {
        margin-right: 10px;
    }
    .ml30 {
        margin-left: 30px;
    }
    .ml10 {
        margin-left: 10px;
    }
    .mt50 {
        margin-top: 50px;
    }
    .mt20 {
        margin-top: 20px;
    }
    .mt30 {
        margin-top: 30px;
    }
    .mr40 {
        margin-right: 40px;
    }
    .w130 {
        width: 130px;
    }
    .remark {
        font-size: 16px;
        font-weight: 450;
    }
    .mt10 {
        margin-top: 10px;
    }
    .w70 {
        width: 78px;
    }
    .ml80 {
        margin-left: 80px;
    }
    .w400 {
        width: 400px;
    }
    .w300 {
        width: 300px;
    }
    .w80 {
        width: 91px;
    }
    .ant-calendar-picker {
        width: 300px !important;
    }

}