// 以下是分类标签样式
.flex {
  display: flex;
}
.margin5 {
  margin: 5px;
}
.selectWidth {
  min-width: 80px;
}
.margin010 {
  margin: 0 10px;
}
.margin105 {
  margin: 10px 5px;
}
.mr15 {
  margin-right: 15px;
}
.tableHeader {
  color: rgb(0, 179, 238);
  font-size: 1.7rem;
  font-weight: bold;
  padding-left: 10px;
}
.w500 {
  width: 500px;
  margin-right: 20px;
}
.mb10 {
  margin-bottom: 10px;
}