.exerciseRankContainer {
    .ant-collapse {
        margin-bottom: 30px;
    }
    .ant-pagination {
        text-align: right;
    }
    .checkClass {
        padding: 20px 0;
    }
    .checkOpt {
        width: 100%;
        label {
            width: 24%;
        }
    }
}
