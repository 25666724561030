@import "~antd/dist/antd.css";

* {
  padding: 0;
  margin: 0;
  list-style: none;
}

body {
  margin: 0;
  min-width: 1200px;
  font-family: "Avenir Next", Avenir, "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "PingFang SC", "Hiragino Sans GB", "Source Han Sans SC", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

.popoverStu {
  max-height: 400px;
  overflow: auto;
  .classStuList {
      padding: 2px 0;
      cursor: pointer;
      font-size: 14px;
  }
  .activeStu {
      color: #108ee9;
      background: #eee;
  }
}

#root, .App, .ant-layout {
  width: 100%;
  height: 100%;
}
.common1Line {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #fff;
  >* {
    margin-right: 20px;
  }
}
.commonDivMb20 >div {
    margin-bottom: 20px;
}
.commonW150 {
  width: 150px;
}
.commonW400 {
  width: 400px;
}
.commonFlexLR {
  display: flex;
  justify-content: space-between;
  .commonLeft {
    flex: 1;
  }
  .commonsubmit {
    width: 230px;
    margin-left: 20px;
  }
}
.commonSearchBox {
  background: #fff;
  padding: 20px 20px 0;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  .itemSearchBox, >div {
    margin: 0 35px 20px 0;
  }
}

label {
  display: inline-block;
  min-width: 4em;
  margin-right: 10px;
  color: #000;
}

.ellipsis1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ellipsis1 > p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis1 > p:not(:first-child) {
  display: none;
}
.ellipsis1 img {
  max-width: 120px;
  height: auto;
}

.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis2 > p:not(:first-child) {
  display: none;
}
.ellipsis2 img {
  max-width: 120px;
  height: auto;
}
.richImg {
  width: 56px;
  height: 98px;
  overflow: hidden;
  img {
    width: 56px;
    height: auto;
  }
}
.cardR {
  width: 230px;
  position: fixed;
  top: 60px;
  right: 20px;
}
.commonTableBtn {
  button {
    margin: 0 5px 5px 0;
  }
}
.commonTableTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}
div.ant-typography {
  font-size: 14px;
  margin-bottom: 0;
}
.ant-btn {
  border-radius: 4px;
}
.ant-btn-link {
  padding-left: 0;
}
.ant-breadcrumb-link {
  font-size: 14px !important;
}
.ant-space-vertical {
  width: 100%;
}
.ant-modal {
  min-width: 600px;
  // max-width: 900px;
}
.ant-table-title {
  font-size: 16px;
  font-weight: bold;
  color: cornflowerblue;
}
.ant-tooltip {
  max-width: 600px;
}
.ant-tooltip-inner img {
  max-width: 220px;
  height: auto;
}
.ant-card {
  z-index: 10;
  margin-bottom: 10px;
  box-shadow: 1px 1px 6px #d8d8d8;
}
.ant-breadcrumb {
  padding: 10px;
  background: #fff;
  font-weight: bold;
  margin-bottom: 20px;
}
.stuInfoForm {
  padding: 10px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  .ant-form-item {
    margin-bottom: 20px !important;
  }
}
.ant-layout-content {
  overflow: auto;
  margin: 10px;
}
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  background: #fff;
  border-bottom: 1px solid #eee;
}
.ant-layout-sider-children {
  height: auto;
}
.ant-table-cell {
  word-break: break-all;
  font-weight: 500;
}
.ant-layout-sider {
  overflow: auto;
  font-weight: bold;
}

.logo {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  background: #001e37;
}
.logoIcon {
  width: 30px;
  height: 30px;
}
.loginBox {
  text-align: center;
  display: flex;
  padding-top: 10%;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg') no-repeat center/100% 100%;
  .titleLogin {
    width: 200px;
    color: #666;
    font-size: 24px;
    text-align: center;
    margin-bottom: 40px;
    border-bottom: 1px solid #999;
  }
}

.cardBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.whyColorfulRenderContainerWhy {
  display: flex;
  align-items: center;
  .colorfulDotCls {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;
  }
}
#video-upload {
  margin-top: 8px;
  cursor: pointer;
}