.answerCardUnit {
  display: inline-block;
  min-width: 100px;
  box-sizing: content-box;
  background: #F2F2F2;
  height: 108px;
  padding: 12px 4px;
  margin: 0 24px 24px 0;
  overflow: hidden;
  cursor: pointer;
  .topRow {
    margin-bottom: 18px;
  }
  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .bottomRow {
    flex-wrap: wrap;
  }
  .questionIndexC {
    font-size:15px;
    font-weight:500;
    color:rgba(50,50,50,1);
    margin-right: 8px;
  }
  .userAnswer {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    text-align: center;
    line-height: 44px;
    font-size:22px;
    font-weight:500;
    background: #FA4F48;
    color: #fff;
    margin-right: 12px;
    flex-shrink: 0;
  }
  .userAnswerIsRight {
    background: #44D7B6;
    color: #fff;
  }
  .smallUserAnswer {
    font-size: 8px;
  }
  .averageAccurayC {
    font-size:14px;
    font-weight:500;
    color:rgba(50,50,50,1);
  }
  .optionUnit {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .optionKey {
      display: inline-block;
      width: 16px;
      height: 16px;
      overflow: hidden;
      text-align: center;
      line-height: 16px;
      background: #fff;
      color: #999;
      font-size: 12px;
      margin-right: 4px;
    }
    .isCorrect {
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      margin-right: 4px;
      background: #44D7B6;
      color: #fff;
    }
    .choiceChosedNum {
      font-size:12px;
      font-weight:300;
      color:rgba(153,153,153,1);
    }
  }
}