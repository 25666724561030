.examineDetailPageStyle {
  .infoBox {
    width: 100%;
    height: auto;
    padding: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    border: 1px solid orange;
    background: #fffbe3;
  }
  .inputRow {
    margin-bottom: 20px;
  }
  .flexrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .flexg {
    flex-grow: 1;
  }
  .flexcolumn {
    display: flex;
    flex-direction: row;

  }
  .flexcolumn label{
    width:84px ;
    height: 18px;
  }
  .input400{
    width: 320px !important;
  }
  .submitBtn {
    position: absolute !important;
    width: 150px;
    z-index: 100;
    right: 30px;
    top: 82px;
  }
  .flexrow label {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 7em;
  }
  .select320 {
    width: 100% !important;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mr15 {
    margin-right: 15px;
  }
  .gotoAdd {
    position: fixed !important;
    top: 280px !important;
    right: 30px !important;
    padding: 4px 20px !important;
    z-index: 10;
    background: #fff;
  }
  .ant-anchor-link-title {
    color: #49a9ee !important;
  }
}