.userConfigPageByExcelWrapper {
  .selectRow {
    margin: 20px 0;
  }
  .mb40 {
    margin-bottom: 40px;
  }
  .importColor {
    color: #FA4F48;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .mb5 {
    margin-bottom: 5px;
  }
  .w500 {
    width: 500px;
  }
  .loadingBgFixed {
    position: fixed;
    top: 0; bottom: 0;
    left: 200px; right: 0;
    z-index: 2;
    background: rgba(255, 255, 255, .5);
    .loadingWrapper {
      position: absolute;
      top: 50%; left: 50%;
      width: 40px;
      height: 40px;
      transform: translate(-50%, -50%);
    }
  }
}