.bannerList {
  .bannerImg {
    width: 375px;
    height: 136px;
  }
  .a {
    display: block;
    width: 100%;
    height: 100%;
  }
  .i {
    color: #00b3ee;
  }
  .mb15 {
    margin-bottom: 15px;
  }
}