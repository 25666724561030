.systemMenu {
    .searchBox {
        display: flex;
        flex-wrap: wrap;
        label {
            margin-right: 10px;
        }
        > div {
            margin: 0 20px 20px 0;
        }
    }
}
.systemM {
    display: flex;
    flex-wrap: wrap;
    label {
        display: inline-block;
        margin-right: 10px;
        width: 5em;
    }
    > div {
        margin: 0 10px 20px 0;
    }
    .helf {
        width: 45%;
    }
    .selectIcon {
        display: inline-block;
        width: 250px;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        border: 1px solid #d4d4d4;
        border-radius: 5px;
    }
}
.noMore3Line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .noMore {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: none;
    -webkit-box-orient: vertical;
  }