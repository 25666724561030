.inquiry-container {
    .classL {
        background: #f2f2f2;
        padding: 4px 8px;
        border-radius: 4px;
    }
    .ant-menu {
        height: 90%;
        overflow: auto;
        color: #333;
    }
    .remark {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .active {
        color: #108ee9;
        cursor: pointer;
    }
    .tableHeader {
        color: rgb(0, 179, 238);
        font-size: 16px;
        font-weight: bold;
        padding: 0 10px;
    }
    .flexg {
        flex-grow: 1;
    }
    .mr10 {
        margin-right: 10px;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
    }
    .pageMenu {
        font-size: 20px;
        height: 70px;
        padding-top: 10px;
    }
    .inquiry {
        background-color: #fff;
    }
    .mt30 {
        margin-top: 30px;
    }
    .mr30 {
        margin-right: 30px;
    }
    .mr50 {
        margin-right: 50px;
    }
    .mt10 {
        margin-top: 10px;
    }
}