.flecrc {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mr60 {
    margin-right: 60px;
  }
  .fontf {
    color: #fff !important;
  }