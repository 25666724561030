.box {
  display: flex;
  flex-direction: row;
}
.context {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.submit {
  width: 20%
}
.ant-calendar-picker {
  width: 120px;
}