.systemL {
    .searchBox {
        display: flex;
        flex-wrap: wrap;
        label {
            margin-right: 10px;
        }
        > div {
            margin: 0 20px 20px 0;
        }
    }
}