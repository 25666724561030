.dianpinctonaienr {
  .row23029032h {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .selcectioncsod {
      width: 400px;
    }
  }
  .flexc {
    display: flex;
    align-items: center;
  }
  .partcls+.partcls {
    margin-top: 30px;
  }
}
