.queryDetailContainer {
    .active {
        color: #108ee9;
        cursor: pointer;
    }
    .changeStu {
        background: #f2f2f2;
        position: sticky;
        top: 0;
        z-index: 10;
        padding: 10px 0;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
    }
    .tableHeader {
        color: rgb(0, 179, 238);
        font-size: 1.7rem;
        font-weight: bold;
        padding: 0 10px;
    }
    .mr10 {
        margin-right: 10px;
    }
    .mr30 {
        margin-right: 30px;
    }
    .mt30 {
        margin-top: 30px;
    }
    .w60 {
        width: 60px;
    }
    .flexg {
        flex-grow: 1;
    }
    .mt5 {
        margin-top: 5px;
    }
    .classStyle {
        margin-left: 70px;
        color: red;
        font-size: 12px;
    }
}
.inModal {
    .mt10 {
        margin-top: 10px;
    }
}