.xingce-container {
    width: 100%;
    min-height: 500px;
    .chart-div {
        width: 100%;
        height: 350px;
        padding: 15px 15px 15px 0;
        margin-bottom: 10px;
    }
    .exercise-container {
        width: 100%;
        min-height: 600px;
        padding-bottom: 50px;
    }
    .spanRight {
        margin-right: 20px !important;
        font-size: 12px;
    }
    .right {
        background: #2D93F7;
    }
    .wrong {
        background: #F82E06;
    }
    .undo {
        background: #f2f2f2;
        color: #555;
    }
    .card-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 32px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 500;
        height: 48px;
        line-height: 48px;
        border-top: 1px solid rgb(233,233,233);
    }
    .ant-card-body {
        padding-bottom: 72px !important;
    }
    .row-ques {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left-num {
            margin-right: 10px;
        }
    }
    .icon {
        width: 32px;
        height: 32px;
        margin-right: 3px;
    }
    .timeIcon, .correctIcon {
        width: 22px;
        height: 22px;
    }
    .ant-card-grid {
        box-shadow: none !important;
    }
    .emptyWorkContainer0346 {
        height: 200px;
        line-height: 200px;
        font-size: 28px;
        color: #555;
        text-align: center;
    }
    .singleModule {
        width: 96%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #108ee9;
        font-size: 16px;
        font-weight: 500;
    }
    .mr30 {
        margin-right: 30px;
    }
    .Positioning {
        position: fixed;
        right: 10px;;
        background: #f2f2f2;
        top: 60px;
        z-index: 100;
    }
    .ant-card {
        z-index: 0;
    }
    .outside {
        border: 1px solid red;
    }
}
