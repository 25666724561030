.userInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    .infoBox {
       text-align: center;
    }
    .upP {
        margin-bottom: 20px;
    }
    .btnP {
        margin-left: 60px;
    }
    label {
        display: inline-block;
        width: 60px;
        text-align: right;
        margin-right: 10px;
    }
    .avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-bottom: 20px;
    }
    .listUser {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #eee;
        padding: 10px 0;
    }
}
