.contract-order {
    .active {
        color: #108ee9;
        cursor: pointer;
    }
    .tableHeader {
        color: rgb(0, 179, 238);
        font-size: 16px;
        font-weight: bold;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .flexg {
        flex-grow: 1;
    }
    .headerButton {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .mr10 {
        margin-right: 10px;
    }
    .mr30 {
        margin-right: 30px;
    }
    .loadingBgFixed {
        position: fixed;
        top: 0; bottom: 0;
        left: 200px; right: 0;
        z-index: 2;
        background: rgba(255, 255, 255, .5);
        .loadingWrapper {
            position: absolute;
            top: 50%; left: 50%;
            width: 40px;
            height: 40px;
            transform: translate(-50%, -50%);
        }
    }
    .mt10 {
        margin-top: 10px;
    }
    .mt30 {
        margin-top: 30px;
    }
    .mt {
        margin-top: 10px;
    }
    .mt4 {
        margin-top: 4px;
    }
    .mb {
        margin-bottom: 10px;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
    }
    .conditionContainer {
        margin: 20px 20px 0px 0px;
        align-items: center;
    }
    .ml10 {
        margin-left: 10px;
    }
    .mt20 {
        margin-top: 20px;
    }
    .ml50 {
        margin-left: 50px;
    }
    .detail {
        color: #1283e6;
        cursor: pointer;
    }
}