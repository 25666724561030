.calendarBox {
     display: flex;
     .listD {
          display: inline-block;
          background: #108ee9;
          color: #fff;
          margin: 5px 10px 5px 0;
          padding: 2px 4px;
          border-radius: 4px;
     }
     .ant-picker-content {
          text-align: center;
     }
     .ant-picker-calendar-date {
          padding: 0 !important;
          margin: 0 !important;
     }
     .ant-picker-content td {
          width: 20px !important;
     }
     .ant-picker-calendar-date-content {
          height: 30px !important;
     }
     .m20 {
          margin: 0 20px 20px 0;
     }
     .sel {
          background: #bfd1f9;
     }
     .cell2 {
          width: 100%;
          height: 40px;
          margin-bottom: 4px;
     }
     .cell {
          color: #fff;
          width: 100%;
          height: 40px;
          line-height: 16px;
          padding: 2px 4px;
          font-weight: bold;
          opacity: 0.6;
          margin-bottom: 4px;
     }
     .start {
          border-radius: 20px 0 0 20px;
          opacity: 1;
     }
     .end {
          border-radius: 0 20px 20px 0;
          opacity: 1;
     }
     .one {
          border-radius: 20px;
          opacity: 1;
     }
     .bg1 {
          background: #ffa500;
     }
     .bg2 {
          background: #2db7f5;
     }
     .bg3 {
          padding: 2px 4px;
          font-weight: bold;
          color: #fff;
          border-radius: 4px;
          background: #f52d2d;
     }
}