.essayCategory {
  .mb15 {
    margin-bottom: 15px;
  }
  .mb15 {
    margin-bottom: 15px;
  }
  .padding10 {
    padding: 10px;
  }
  .bgw {
    background: #fff;
  }
  .mr5 {
    margin-right: 5px;
  }
  .mb25 {
    margin-bottom: 25px;
  }
}