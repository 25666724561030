.essayListContainer {
  .inlineDiv {
    display: inline-block;
    margin-right: 10px;
  }
  .w300 {
    width: 100px;
  }
  .mr10 {
    margin-right: 10px;
  }
}