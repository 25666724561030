.imgListGallery {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 0 !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li {
        list-style-type: none;
        margin-right: 10px;
        margin-bottom: 15px;
        position: relative;
        img {
            width: 320px;
            height: 240px;
        }
        .modal-tips {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-content: center;
            text-align: center;
            flex-direction: column;
        }
    }
}
.normalBorderImg {
    padding: 7px 7px;
    border: 3px solid #999;
}
.hide {
    display: none;
}
.uploadFileTips {
    font-size: 16px;
    color: red;
    margin-top: 7px;
}
.input {
    visibility: hidden;;
}