.student-info {
    .tableHeader {
        color: rgb(0, 179, 238);
        font-size: 16px;
        font-weight: bold;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .flexg {
        flex-grow: 1;
    }
    .mr10 {
        margin-right: 10px;
    }
    .mb20 {
        margin-bottom: 20px;
    }
    .mt5 {
        margin-top: 5px;
    }
    .mr30 {
        margin-right: 30px;
    }
    .conditionContainer {
        margin: 0 40px 20px 0;
        align-items: center;
        label {
            min-width: 4em;
            margin-right: 5px;
        }
    }
    .flexRow {
        display: flex;
        flex-direction: row;
    }
    .mr5 {
        margin-right: 5px;
    }
    .mb5 {
        margin-bottom: 5px;
    }
    .ml50 {
        margin-left: 50px;
    }
    .ml30 {
        margin-left: 30px;
    }
    .mt10 {
        margin-top: 10px;
    }
}