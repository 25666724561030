.examineList {
  .mr20 {
    margin-right: 20px !important;
  }
  .aSpan {
    cursor: pointer;
    color: #108ee9;
    font-size: 14px;
  }
  .opacityInput {
    display: inline-block;
    width: 70px;
    height: 24px;
    opacity: 0;
  }
  .btnWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .pdfButton {
    width: 70px;
    height: 24px;
    position: relative;
    .btnName {
      position: absolute;
      top: 67%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.loadingWrapper {
  position: fixed;
  z-index: 300;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(255, 255, 255, .55);
  .progressWrapper {
    position: fixed;
    z-index: 301;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }
}