.classTestInfoContainer {
  .infoBox {
    width: 100%;
    height: auto;
    padding: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    border: 1px solid orange;
    background: #fffbe3;
  }
  .cardContainer {
    position: absolute;
    top: 60px;
    right: 30px;
    width: 210px;
    height: 100px;
  }
  .classInfoBtn {
    margin-left: 20px;
  }
}