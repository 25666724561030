.upload {
  display: flex;
  flex-direction: row;
}
.up {
  margin-left: 150px;
}
.table {
  margin-top: 20px;
}
.main-container .title img {
  max-width: 100px !important;
  height: auto;
}