.vvPageContainer {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: center;
  .videoContainer {
    min-width: 440px;
    min-height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .video {
    width: 440px;
    height: 300px;
    margin-bottom: 20px;
  }
  .length, .error {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
  }
  .error {
    color: red;
  }
  .w500 {
    width: 500px;
    margin: 0 10px;
  }
}