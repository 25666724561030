.tagList span {
    display: inline-block;
    color: #fff;
    background: #14aef5;
    margin: 0 4px 4px 0;
    padding: 0 4px;
    font-size: 12px;
    border-radius: 5px;
}
.corPreModel {
    .ant-modal-body {
        font-size: 12px;
    }
    .index {
        width: 25px;
        height: 25px;
        display: inline-block;
        border: 1px solid #999;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
        margin-right: 10px;
    }
    img {
        max-width: 450px;
        height: auto;
    }
    .right {
        font-weight: bold;
        border: 1px solid #14aef5;
        background: #78c9ee;
        color: #fff;
    }
    .choice-item {
        display: flex;
        margin-bottom: 10px;
    }
    .item-content {
        flex: 1;
    }
    .explain-content {
        img {
            max-height: 30px;
            width: auto;
        }
    }
}