.interviewDetailPage {
    background: #F2F2F2;
    .modify {
        margin-left: 10px;
    }
    .notExercise {
        width: 100%;
        height: 200px;
        text-align: center;
        line-height: 200px;
        font-size: 22px;
        font-weight: 500;
    }
    .bottomModule {
        padding: 10px;
        background: #FFFFFF;
    }
    .flexrc {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .childDiv {
        font-size: 14px;
        font-weight: 400;
    }
    .mt10 {
        margin-top: 10px;
    }
    .borderBottom {
        border-bottom: 1px solid #f0f0f0;
    }
    .flexG {
        flex-grow: 1;
    }
    .mb5 {
        margin-bottom: 5px;
    }
    .blue {
        color: #60AEEF;
        font-size: 16px;
        font-weight: 500;
    }
    .fz20 {
        font-size: 18px;
        color: #565964;
        font-weight: 400;
    }
    .marginRight25 {
        margin-right: 25px;
    }
    .questionName {
        width: 98%;
        min-height: 200px;
        margin: 0 auto 10px; 
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #999999;
    }
    .remarkTotal {
        margin: 20px auto;
    }
    .remarkStyle {
        min-height: 80px;
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #999999;
        white-space: pre-wrap;
    }
    .pageBase {
        width: 98%;
        margin: 10px auto 0; 
        min-height: 200px;
        padding: 10px;
        border-radius: 5px;
        background: #fff;
        border: 1px solid #999999;
        margin-bottom: 100px;
    }
    .overallRate {
        width: 98%;
        margin: 10px auto 0; 
    }
    .por{
        position: relative;
    }
    .mb20 {
        margin-bottom: 20px;
    }
    .comment {
        display: flex;
        flex-direction: row;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .height100 {
        height: 100%;
    }
    .nextAndPrevBtn {
        position: sticky;
        bottom: 0;
        z-index: 10;
        height: 50px;
        background: #f2f2f2;
        transition: all ease 2s;
    }
    .userName {
        padding: 2px 10px;
        color: #108ee9;
        border: 1px solid #108ee9;
    }
    .mr30 {
        margin-right: 30px;
    }
    .materials {
        margin-bottom: 10px;
    }
    .exerciseTitle {
        font-size: 12px;
        font-weight: 600;
        color: #262626;
        margin-bottom: 3px;
    }
    .nono {
        padding: 20px 15px;
        font-size: 16px;
        font-weight: bold;
        color: red;
    }
    .fz14 {
        font-size: 14px;
    }
}