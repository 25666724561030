.interviewStudentPageWrapper {
  .mr30 {
    margin-right: 30px !important;
  }
  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .inputFilterWrapper {
    margin-bottom: 8px;
  }
  .selectFilterWrapper {
    flex-wrap: wrap;
  }
  .mlr30 {
    margin: 0 30px;
  }
  .inputFilterItem, .selectFilterItem {
    margin-right: 40px;
    label {
      margin-right: 10px;
      // display: inline-block;
      // width: 8em;
      flex-shrink: 0;
    }
  }
}