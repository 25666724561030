.image-picker {
  height: 250px;
  .ant-modal-body {
    padding: 0;
  }
  .link {
    color: #1890ff;
    cursor: pointer;
    font-size: 12px;
  }
  .footer-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    text-align: right;
    border-top: 1px solid #e9e9e9;
    .page-btns {
      flex: 8;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .confirm-btns {
      flex: 2;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-content-holder {
    height: 350px;
    overflow: auto;
  }
}
