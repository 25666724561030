.studentDetailWrapper {
    .studentDetail-container {
        background-color: #fff;
        .flexrc {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .flexg {
            flex-grow: 1;
        }
        .filterDiv {
            border-bottom: 1px solid #f0f0f0;
        }
        .paddinglr {
            padding: 15px 20px;
        }
        .metadata-container {
            height: 40px;
            border-bottom: 1px solid #f0f0f0;
        }
        .spanRight {
            margin-right: 15px;
        }
        .recentTime {
            display: inline-block;
            color: red;
            font-size: 16px;
        }
    }
    .bottom10 {
        margin-bottom: 10px;
        font-weight: bold;
    }
    .red {
        color: red;
        margin-right: 6px;
    }
    .nextAndPrevBtn {
        position: sticky;
        bottom: 0;
        height: 50px;
        background: #f2f2f2;
        opacity: 0;
        transition: all ease 2s;
    }
    .opacity1 {
        opacity: 1;
    }
    .flexg {
        flex-grow: 1;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .height100 {
        height: 100%;
    }
    .mr30 {
        margin-right: 30px;
    }
    .userName {
        padding: 2px 10px;
        color: #108ee9;
        border: 1px solid #108ee9;
    }
    .fixedMap {
        position: fixed;
        top: 50%;
        z-index: 10;
        background: #fff;
        width: 116px;
        height: 78px;
        transform: translateY(-50%);
        right: 20px;
        border: 2px dashed #f0f0f0;
        .mapFlex {
            width: 100%;
            height: 100%;
            display: flex;
            font-size: 12px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #49a9ee;
            font-weight: 400;
            span {
                cursor: pointer;
            }
            span + span {
                margin-top: 5px;
            }
        }
    }
}