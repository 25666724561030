.questionSelectCardComponent {
  .flexRow {
    display: flex;
    flex-direction: row;
    margin: 20px 0 10px 0;
    flex-wrap: wrap;
  }
  .inlineDiv {
    display: inline-block;
    margin: 0 20px 10px 0;
  }
  .flexRow2 {
    display: inline;
    margin: 0 0 20px 0; 
  }
  .inlineDiv2 {
    display: inline;
    margin: 0 9px 9px 0px;
  }
  .w101 {
    width: 100px !important;
  }
  .w301 {
    width: 300px;
  }
  .w60 {
    width: 60px;
  }
  .search-input {
    margin-bottom: 0;
  }
  .mr15 {
    margin-right: 15px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .no {
    color: #f04134;
  }
  .noMore3Line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}