.previewModal {
    height: 450px;
    overflow: auto;
    padding: 0 10px;
    font-size: 12px;
    img {
        max-width: 450px;
        height: auto;
    }
    .footerPre {
        position: absolute;
        bottom: 10px;
        right: 20px;
        width: 92%;
        background: #fff;
        text-align: right;
        padding-top: 10px;
        border-top: 1px solid #e3e3e3;
    }
    .pre {
        margin-right: 20px;
    }
    .type {
        margin-right: 50px;
    }
    .index {
        width: 25px;
        height: 25px;
        display: inline-block;
        border: 1px solid #999;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
        margin-right: 10px;
    }
    .right {
        font-weight: bold;
        border: 1px solid #14aef5;
        background: #78c9ee;
        color: #fff;
    }
    .choice-item {
        display: flex;
        margin-bottom: 10px;
    }
    .item-content {
        flex: 1;
    }
}