.module{
    .rootName, .sonName {
        font-size: 16px;
        color: #0983fc;
        margin-right: 20px;
    }
    .sonName {
        font-size: 14px;
    }
    .iconModule {
        color: #a9a9a9;
        margin-right: 15px;
    }
    .ant-tree {
        margin-bottom: 30px;
        border-radius: 20px;
    }
    .ant-tree-switcher {
        color: #29B6F6;
    }
    .ant-tree-treenode {
        padding: 20px;
    }
}