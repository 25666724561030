.recordBox {
    display: flex;
    align-items: center;
    background: #d2eafb;
    height: 70px;
    padding: 0 10px;
    .reBtn {
        margin-right: 10px;
    }
}
