.formhooks{
  :global{
   .ant-row{
    display: flex;
    flex-wrap: wrap;
   }
  }
}
.center {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .flexg {
    flex-grow: 1;
  }
}
