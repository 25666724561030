.bannerList {
  .bannerImg {
    width: 350px;
    height: 120px;
  }
  .topTitle {
		position: sticky;
		top: 0;
		padding: 15px;
		z-index: 100;
		box-shadow: 1px 1px 6px #999;
	}
}