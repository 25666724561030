.batch-class-container {
    .active {
        color: #108ee9;
        cursor: pointer;
    }
    .position {
		position: fixed;
		top: 70px;
		right: 20px;
    }
    .mr10 {
        margin-right: 10px;
    }
    .mb30 {
        margin-bottom: 30px;
    }
    .mt30 {
        margin-top: 30px;
    }
    .flexG {
        flex-grow: 1;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
    }
    .mr20 {
        margin-right: 20px;
    }
    .ml30 {
        margin-left: 30px;
    }
}