.classTestContainer {
    .mb10 {
		margin-bottom: 10px;
    }
    .flexRow {
		display: flex;
		flex-direction: row;
		align-items: center;
    }
    .position {
      position: fixed;
      top: 70px;
      right: 20px;
      z-index: 10;
    }
    .ml10 {
        margin-left: 10px;
    }
}